/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState, useRef } from 'react'

const noop = () => {}

const inputStyles = {
  border: '1px solid black',
  fontSize: '1rem'
}
const buttonStyles = {
  border: '1px solid black',
  fontSize: '1rem'
}

const Input = ({onInput = noop, placeholder = ""}) => {
  const [value, setValue] = useState('')
  const inputEl = useRef(null)
  const onChange = (e) => setValue(e.target.value)
  const onClick = () => {
    if (!value) return
    onInput(value)
    setValue('')
    inputEl.current.focus()
  }
  const onSubmit = (e) => {
    e.preventDefault()
    onClick()
  }
  return <div>
    <form onSubmit={onSubmit}>
      <input type="text" value={value} onChange={onChange} sx={inputStyles} placeholder={placeholder} ref={inputEl}/>
      <button onClick={onClick} sx={buttonStyles}>ADD</button>
    </form>
  </div>
}

export default Input