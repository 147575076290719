/** @jsx jsx */
import { jsx } from 'theme-ui'
import { ThemeProvider, Styled } from 'theme-ui'
import { Global } from '@emotion/core'
import { BrowserRouter as Router } from 'react-router-dom'
import { Switch, Route } from 'react-router'


import './firebase'
import theme, { globalStyles } from './theme'
import Home from './Home';
import Todos from './Todos'

const App = () => {
  return <ThemeProvider theme={theme}>
    <Global
      styles={globalStyles}    
    />
    <Styled.root sx={{ p: [2], m: 0}}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/list/:listId" component={Todos}/>
        </Switch>
      </Router>
    </Styled.root>
  </ThemeProvider>
}

export default App;
