/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Link } from 'react-router-dom'

import { db } from './firebase'
import Input from './Input'

const noop = () => {}

const byChecked = ([idA, itemA], [idB, itemB]) => {
  if (itemA.checked && !itemB.checked) return 1
  if (!itemA.checked && itemB.checked) return -1
  if (itemA.name > itemB.name) return 1
  if (itemA.name < itemB.name) return -1
  return 0
}

const Item = ({item, onClick = noop, id}) => <li sx={{textDecoration: item.checked ? 'line-through' : 'none', cursor: 'pointer', userSelect: 'none'}} onClick={() => onClick(id)}>{item.name}</li>

const Items = ({items = {}, onClickItem = noop}) => <ul>
  {Object.entries(items).sort(byChecked).map(([id, item]) => <Item key={id} item={item} id={id} onClick={onClickItem}/>)}
</ul>


class Todos extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      items: {},
      name: ''
    }
  }

  componentDidMount = () => {
    const id = this.props.match.params.listId
    this.setState({listId: id})
    this.listRef = db.ref(`lists/${id}`)
    this.listRef.on('value', this.onData)
  }

  componentWillUnmount = () => {
    this.listRef.off()
  }

  onData = (snapshot) => {
    const snap = snapshot.val()
    if (!snap) {
      this.props.history.push('/')
      return
    }
    const {items, name} = snap
    this.setState({items, name})
  }

  setChecked = (id, checked = true) => {
    db.ref(`lists/${this.state.listId}/items/${id}/checked`).set(checked)
  }

  onClickItem = id => {
    const wasChecked = this.state.items[id].checked
    this.setState({
      items: {
        ...this.state.items,
        [id]: {
          ...this.state.items[id],
          checked: !wasChecked
        }
      }
    })
    this.setChecked(id, !wasChecked)
  }

  addItem = (name, checked=false) => {
    db.ref(`lists/${this.state.listId}/items`).push({name, checked})
  }

  render(){
    return <div>
      <Link to="/"><h1>{this.state.name}</h1></Link>
      <Items items={this.state.items} onClickItem={this.onClickItem}/>
      <Input onInput={this.addItem} placeholder="add an item"/>
    </div>
  }  
}

export default Todos