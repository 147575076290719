import * as firebase from 'firebase/app'
import 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyDbHiPjX8dvi7MzRMZb7UO3YOBvW2NgGyQ",
  authDomain: "dotos-1e595.firebaseapp.com",
  databaseURL: "https://dotos-1e595.firebaseio.com",
  projectId: "dotos-1e595",
  storageBucket: "",
  messagingSenderId: "1033520264707",
  appId: "1:1033520264707:web:3dc2c2db2ebee179"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.database()