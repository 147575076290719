/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Link } from 'react-router-dom'

import { db } from './firebase'
import Input from './Input'
import { slugify } from './utils'

class Home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      lists: {}
    }
  }
  componentDidMount = () => {
    const id = this.props.match.params.listId
    this.setState({listId: id})
    this.listRef = db.ref(`/`)
    this.listRef.on('value', this.onData)
  }

  componentWillUnmount = () => {
    this.listRef.off()
  }

  onData = (snapshot) => {
    const snap = snapshot.val()
    if (!snap) return
    this.setState({lists: snap.lists})
  }

  addList = (name = '') => {
    if (!name) return
    const key = slugify(name)
    db.ref(`lists/${key}/name`).set(name)
    this.props.history.push(`/list/${key}`)

  }

  render(){
    return <div>
      <h1>Todos</h1>
      <ul>
        { Object.entries(this.state.lists).map(([id, list]) => <li key={id}>
          <Link to={`/list/${id}`}>{list.name}</Link>
        </li>)}
      </ul>
      <Input onInput={this.addList} placeholder="Add a list"/>
  </div>
  }
}
  

export default Home